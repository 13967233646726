.game-card {
  margin-top: 32px;
  color: white;
}
/* .game-card:hover {
  background-color: #2a9fd6;
} */

.card-body {
  padding-bottom: 0px !important;
}

.game-title {
  font-size: 1.3em;
}

.controller-icons {
  justify-content: space-around;
  flex-wrap: nowrap !important;
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  margin-top: 16px;
}

.release-date {
  margin-top: 8px;
  color: rgba(255, 255, 255, 0.25);
}

.inner-card {
  margin: 8px 0;
  border: none !important;
}

.genre {
  color: rgba(255, 255, 255, 0.25);
}
